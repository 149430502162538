// import "survey-react/modern.min.css"; //tema modern
// import 'survey-react/survey.min.css'; //tema predefinito
import React, { useState, useCallback } from "react";
import { Helmet } from "react-helmet";
import { generateHeadTags } from "react-seo-tools/lib/generateHeadTags";
import "./defaultV2.min.css"; //tema defaultV2, molto carino
import "./defaultV2Dark.css"
import { StylesManager, Model, Survey } from "survey-react";
import surveyJson from "./Survey.json";
import emailjs from "@emailjs/browser";
import * as widgets from "surveyjs-widgets";
import "./nouislider.css";
import "./nouislider.fix.css";
import * as SurveyCore from "survey-react";
import Navbar from "./navbar";
import { Triangle } from "react-loader-spinner"

/* spazio per le note:
 * - per il sondaggio si puo usare direttamente il creator di surveyjs
 * - al momento inviamo una stringa in formato json alla nostra mail, si dovrebbe rendere piu leggibile
 *
 *
 *
 * - per fare render condizionato di un'opzione:
 *   visibleIf: "<{variabile}> <condizione> <elemento da paragonare>"
 *   es. visibleIf: "{panel.isalive} = 'No'"
 *   <script src="https://unpkg.com/surveyjs-widgets@1.9.33/surveyjs-widgets.min.js"></script>
 *
 *
 *
 */

// per scegliere un tema predefinito del survey
StylesManager.applyTheme("defaultV2");
widgets.nouislider(SurveyCore);

function PreventivoRapido() {
  //istanzio il sondaggio, al quale passo il json contenente i dati. Ora è uno state perche cosi si evita di resettarlo.
  const [survey] = useState(new Model(surveyJson));
  const [rangePrezzo, setRangePrezzo] = useState(0);
  const [completato, setCompletato] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const surveyComplete = useCallback((sondaggio) => {
    console.log(sondaggio.data);
    setCompletato(true)
    setIsLoading(true)
    let dataSurvey = sondaggio.data;
    let nameSurvey = dataSurvey.Nome;
    let emailSurvey = dataSurvey.Email.email;
    let priceSurvey = dataSurvey.Prezzo;

    delete dataSurvey.Prezzo;
    delete dataSurvey.Nome;
    delete dataSurvey.Email;

    let emailParams = {
      message: JSON.stringify(dataSurvey),
      name: nameSurvey,
      email: emailSurvey,
      price: priceSurvey,
    };

    emailjs
      .send(
        "service_nwzcaz8",
        "template_q5kphco",
        emailParams,
        "bUNt7egRIHYNJ5Ypk"
      )
      .then(
        (result) => {
          console.log(result.text);
          setIsLoading(false)
          alert("Questionario inviato correttamente");
        },
        (error) => {
          console.log(error.text);
          setIsLoading(false)
          alert(
            "C'è stato un errore durante l'invio del questionario, ricompila le informazioni"
          );
          window.location.reload();
        }
      );
  }, []);

  const calcolaRangePrezzo = (prezzo) => {
    if (prezzo >= 50000) setRangePrezzo(50000);
    if (prezzo < 50000) {
      if (prezzo >= 45000) setRangePrezzo(45000);
      else if (prezzo >= 40000) setRangePrezzo(40000);
      else if (prezzo >= 35000) setRangePrezzo(35000);
      else if (prezzo >= 30000) setRangePrezzo(30000);
      else if (prezzo >= 25000) setRangePrezzo(25000);
      else if (prezzo >= 20000) setRangePrezzo(20000);
      else if (prezzo >= 15000) setRangePrezzo(15000);
      else if (prezzo >= 10000) setRangePrezzo(10000);
      else if (prezzo >= 9500) setRangePrezzo(9500);
      else if (prezzo >= 9000) setRangePrezzo(9000);
      else if (prezzo >= 8500) setRangePrezzo(8500);
      else if (prezzo >= 8000) setRangePrezzo(8000);
      else if (prezzo >= 7500) setRangePrezzo(7500);
      else if (prezzo >= 7000) setRangePrezzo(7000);
      else if (prezzo >= 6500) setRangePrezzo(6500);
      else if (prezzo >= 6000) setRangePrezzo(6000);
      else if (prezzo >= 5500) setRangePrezzo(5500);
      else if (prezzo >= 5000) setRangePrezzo(5000);
      else if (prezzo >= 4500) setRangePrezzo(4500);
      else if (prezzo >= 4000) setRangePrezzo(4000);
      else if (prezzo >= 3500) setRangePrezzo(3500);
      else if (prezzo >= 3000) setRangePrezzo(3000);
      else if (prezzo >= 2500) setRangePrezzo(2500);
      else if (prezzo >= 2000) setRangePrezzo(2000);
      else if (prezzo >= 1500) setRangePrezzo(1500);
      else if (prezzo >= 1000) setRangePrezzo(1000);
      else if (prezzo >= 500) setRangePrezzo(500);
    }
  };
  //Ad ogni cambiamento nel sondaggio esegue questa funzione
  survey.onValueChanged.add((survey) => {
    //(survey, options)
    //Instanzio le variabili data e calcolo. data contine i dati del sondaggio
    //options mostra solo l'elemento cliccato
    // Prezzi sono senza IVA
    let data = survey.data;
    let calcolo = 0;
    let mockupCalcolato = false;
    // console.log(data)
    //Per ogni dato del sondaggio, esclusi quelli nel if, esegue una somma con un valore al momento uguale a tutti
    Object.keys(data).map((el) => {
      // if(el !== "Nome" && el !== "Email" && el !== "Prezzo" && el !== "TitoloDescrizione" && el !== "Lista pagine" ) calcolo = calcolo + (data[el] !== undefined && 10)
      switch (data[el]) {
        case "Vetrina":
          calcolo = calcolo + 1160;
          break;
        case "Ecommerce":
        case "Portale+Backend":
        case "Hardware":
          calcolo = calcolo + 2900;
          break;
        case "App":
          calcolo = calcolo + 7250;
          break;
        case "SW ad-hoc":
          calcolo = calcolo + 4350;
          break;
        case "Firmware industriale":
          calcolo = calcolo + 5800;
          break;
        case "Game":
          calcolo = calcolo + 11600;
          break;
        case "1000-5000":
        case ">5000":
        case "Mondo":
        case "Fisico":
        case "entrambi": // Piattaforma App
        case "ios":
          calcolo = calcolo + 1450;
          break;
        case "other":
        case "Entrambi": // Tipologia Prodotti
          calcolo = calcolo + 2175;
          break;
        case false:
          if (el === "Mockup" || el === "Logotipo" || el === "BrandIdentity") {
            if (
              data["Mockup"] === false &&
              data["Logotipo"] === false &&
              data["BrandIdentity"] === false &&
              !mockupCalcolato
            ) {
              calcolo = calcolo + 3625;
              mockupCalcolato = true;
            }
            if (!mockupCalcolato) calcolo = calcolo + 1200;
          }
          break;
        case true:
          if (el === "SocialMedia") calcolo = calcolo + 2175;
          if (el === "UserManagement") calcolo = calcolo + 2900;
          if (el === "AdsInApp") calcolo = calcolo + 4350;
          if (el === "InAppPurchase") calcolo = calcolo + 7250;
          break;
        default:
          if (el === "Lingue") {
            data[el].forEach((lingua) => {
              if (lingua !== "Italiano" && lingua !== "Inglese") {
                calcolo = calcolo + 2175;
              }
            });
          }
          break;
      }
      return null;
    });
    //Setta il valore calcolato nel campo "Prezzo" impostato in sola lettura
    survey.setValue("Prezzo", calcolo + "€+");
    calcolaRangePrezzo(calcolo);
  });

  //quando il sondaggio è completato, cosa fa? al momento stampa un oggetto nella console
  survey.onComplete.add(surveyComplete);
  // survey.onComplete.add(function (sondaggio) {
  //   console.log( sondaggio.data );
  //   let dataSurvey = sondaggio.data;
  //   let nameSurvey = dataSurvey.Nome;
  //   let emailSurvey = dataSurvey.Email.email;
  //   let priceSurvey = dataSurvey.Prezzo;

  //   delete dataSurvey.Prezzo;
  //   delete dataSurvey.Nome;
  //   delete dataSurvey.Email;

  //   let emailParams = {
  //     message: JSON.stringify(dataSurvey),
  //     name: nameSurvey,
  //     email: emailSurvey,
  //     price: priceSurvey,
  //   };

  //   emailjs
  //     .send(
  //       "service_nwzcaz8",
  //       "template_q5kphco",
  //       emailParams,
  //       "bUNt7egRIHYNJ5Ypk"
  //     )
  //     .then(
  //       (result) => {
  //         console.log(result.text);
  //         alert("Questionario inviato correttamente");
  //       },
  //       (error) => {
  //         console.log(error.text);
  //         alert(
  //           "C'è stato un errore durante l'invio del questionario, ricompila le informazioni"
  //         );
  //         //window.location.reload();
  //       }
  //     );
  // });

  return (
    <section id="preventivoRapido" className=" dark:bg-[#171717] pt-20 md:pt-0">
      <Helmet>
        {generateHeadTags({
          title: "Booster Hub - Preventivo Rapido",
          description:
            "Ottieni il tuo preventivo rispondendo a delle semplici domande.",
          openGraph: {
            type: "website",
            title: "Preventivo Rapido",
            description:
              "Ottieni il tuo preventivo rispondendo a delle semplici domande.",
            image: "",
          },
        })}
      </Helmet>
      <Navbar />
      { isLoading &&
        <div className=" fixed z-30 top-0 flex w-full h-full bg-black/50 backdrop-blur justify-center items-center ">
          <Triangle
            color="#CD122F"
            visible={true}
          />
        </div>
      }
      <Survey model={survey} className="mt-4 min-h-screen" />
      {!completato && <NotificaPrezzo prezzo={rangePrezzo} />}
    </section>
  );
}

export default PreventivoRapido;

function NotificaPrezzo({ prezzo }) {
  return (
    <div
      className={` z-30 text-lg md:text-xl ${
        prezzo !== 0 ? "opacity-100" : "opacity-0"
      } group fixed left-1/2 top-[70px] flex min-h-[56px] w-[50%] -translate-x-1/2 transform flex-row items-center justify-center rounded-lg border-2 border-solid border-[rgba(255,255,255,0.18)] bg-white/60 p-2 shadow-md backdrop-blur-sm transition-all duration-700 ease-in-out md:left-[inherit] md:top-[inherit] md:bottom-4 md:right-4 md:min-h-[100px] md:w-[200px] md:-translate-x-0 md:flex-col md:gap-0 `}
    >
      <span className=" absolute top-full mt-4 h-auto w-3/4 transform rounded-md bg-black/60 p-2 text-center text-white opacity-0 transition-all duration-700 ease-in-out group-hover:opacity-100 md:top-[inherit]  md:bottom-full md:mt-[inherit] md:mb-4">
        {/* I prezzi e le tempistiche indicate sono strettamente indicative. */}
        I prezzi indicati sono strettamnete indicativi.
      </span>
      {/* <p className=' text-center font-bold '>Riepilogo:</p> */}
      <p className=" font-medium ">Prezzo: </p>
      <p className="ml-1 mr-2 font-bold md:ml-0 md:mr-0 "> {prezzo}€+ </p>
      {/* <p className=" font-semibold">Tempistiche: </p>
      <p className=" ml-1 md:ml-0 ">Da definire</p> */}
    </div>
  );
}
