import { React, useRef } from "react";
import UomoInMutande from "../img/UomoInMutande.webp";
import emailjs from "@emailjs/browser";

export default function Contatti() {
  let inputStyle =
    "h-14 px-4 w-full mt-2 dark:placeholder:text-[#BDBDBD] flex border-2 rounded-lg box-border dark:outline-none dark:border-none bg-[#961125]/5 dark:bg-[#666666] ";

  const nomeCognome = useRef(null);
  const email = useRef(null);
  const message = useRef(null);

  const sendEmail = (e) => {
    e.preventDefault();
    let emailParams = {
      message: message.current.value,
      name: nomeCognome.current.value,
      email: email.current.value,
    };
    emailjs
      .send(
        "service_nwzcaz8",
        "template_wye659a",
        emailParams,
        "bUNt7egRIHYNJ5Ypk"
      )
      .then(
        (result) => {
          alert("Messaggio inviato correttamente");
          console.log(result.text);
          window.location.reload();
        },
        (error) => {
          console.log(error.text);
          alert(
            "C'è stato un errore durante l'invio dei dati, ricompila le informazioni"
          );
          window.location.reload();
        }
      );
  };

  return (
    <section
      id="contatti"
      className="my-8 flex h-[90vh] flex-row items-center justify-center bg-[#961125] px-4 md:justify-end md:px-8 xl:gap-28 2xl:gap-56"
    >
      <img
        src={UomoInMutande}
        draggable="false"
        alt=""
        className="hidden self-end lg:flex lg:h-4/5 "
        loading="lazy"
      />
      <form
        onSubmit={sendEmail}
        className="mt-10 flex h-full w-full flex-none flex-col justify-center gap-6 rounded bg-white p-8 font-semibold shadow-xl dark:bg-[#444444] lg:w-2/5 2xl:w-1/3 "
      >
        <p className="text-4xl font-bold">Lavora con noi</p>
        <label>
          <p>Nome e Cognome</p>
          <input
            ref={nomeCognome}
            placeholder="Nome e Cognome..."
            className={inputStyle}
            type="text"
          />
        </label>
        <label>
          <p>Email</p>
          <input
            required
            ref={email}
            placeholder="Email..."
            className={inputStyle}
            type="email"
          />
        </label>
        <label>
          <p>Messaggio</p>
          <textarea
            required
            ref={message}
            placeholder="Messaggio..."
            className=" mt-2 box-border flex h-40 w-full resize-none rounded-lg border-2 bg-[#961125]/5 p-4 outline-none dark:border-none dark:bg-[#666666] dark:placeholder:text-[#BDBDBD] "
          />
        </label>
        <button className="h-14 rounded-lg bg-[#CD122F] font-semibold text-white hover:opacity-90">
          Invia
        </button>
      </form>
    </section>
  );
}
