import UiDesign from "../img/Ui.webp";
import App from "../img/AppDesign.webp";
import Game from "../img/Gamepad.webp";
import Web from "../img/WebDesign.webp";
import Sviluppo from "../img/Sviluppo.webp";
import Api from "../img/Api.webp";
import Background1 from "../img/Background1.webp";
import { Link } from "react-router-dom";

export default function Servizi() {
  const ScrollTo = (e) => {
    document.getElementById(e).scrollIntoView({
      behavior: "smooth",
    });
  };
  return (
    <section
      id="home"
      className=" relative flex flex-col items-center justify-center overflow-x-hidden pt-24 md:mb-8 md:pt-16 "
    >
      <img
        src={Background1}
        draggable="false"
        alt=""
        className="absolute top-0 -left-8 z-0 h-auto w-60 opacity-10 md:opacity-20"
      />
      <img
        src={Background1}
        draggable="false"
        alt=""
        className="absolute top-1/2 -right-8 z-0 h-auto w-60 opacity-10 md:opacity-20"
      />
      <div className="z-10 flex flex-col items-center px-4 lg:w-3/4 2xl:w-3/5">
        <p className="gap-2 text-center text-4xl font-bold">
          Noi sviluppiamo <span className="text-[#CD122F]">SOFTWARE</span>{" "}
        </p>
        <div className="flex flex-col text-center font-semibold md:w-2/5">
          <p className="my-8">
            L'obiettivo è costruire app e siti web esteticamente piacevoli,
            progettati per guidare la crescita delle aziende leader nel mondo.
          </p>
          {/* I tasti potrebbero non servire */}
          <span className="flex flex-row justify-center gap-4">
            <button
              onClick={() => ScrollTo("Progetti")}
              className="h-10 w-40 rounded-full bg-gradient-to-r from-[#CD122F] to-[#961125] font-semibold text-white shadow-lg shadow-gray-400 dark:text-slate-100 dark:shadow-none"
            >
              Progetti
            </button>
            <button
              onClick={() => ScrollTo("portfolio")}
              className="h-10 w-40 rounded-full bg-gradient-to-r from-[#CD122F] to-[#961125] font-semibold text-white shadow-lg shadow-gray-400 dark:text-slate-100 dark:shadow-none"
            >
              Portfolio
            </button>
          </span>
          <span className=" mt-6 flex items-center justify-center md:hidden ">
            <Link
              to={"/preventivo"}
              className=" flex h-10 w-40 items-center justify-center rounded-full bg-gradient-to-r from-[#CD122F] to-[#961125] font-semibold text-white shadow-lg shadow-gray-400 dark:text-slate-100 dark:shadow-none"
            >
              Preventivo
            </Link>
          </span>
        </div>
      </div>
      <div
        id="servizi"
        className="my-8 flex flex-col justify-center lg:w-3/4 2xl:w-3/5"
      >
        <span className="flex flex-col items-center justify-center gap-6 md:flex-row ">
          <p className="text-2xl font-semibold capitalize">
            Cosa <span className="text-[#CD122F]">creiamo!</span>
          </p>
          <p className=" flex border-l-[#CD122F] px-4 text-center text-xs font-semibold md:w-2/5 md:border-l-2 ">
            Lavoriamo in team tra i vari dipartimenti per creare un prodotto che
            rafforzi i marchi dei nostri clienti. Forniamo tutte le soluzioni di
            business nei servizi di base. Siamo un'agenzia indipendente di
            Design & Sviluppo.
          </p>
        </span>
        <div className="my-8 flex flex-row flex-wrap items-center justify-center gap-4 px-4 md:px-0">
          <Tile
            titolo={"GAME DEVELOPMENT"}
            testo={
              "BoosterHub propone servizi di sviluppo game per quasi tutte le piattaforme di gioco"
            }
            Icona={Game}
            ordine=" order-last "
          />
          <Tile
            titolo={"WEB DESIGN & DEVELOPMENT"}
            testo={
              "Progettiamo e realizziamo Website responsive che rendono perfettamente su ogni dispositivo"
            }
            Icona={Web}
            ordine=" order-2 "
          />
          <Tile
            titolo={"UI/UX DESIGN"}
            testo={
              "Interfaccia Utente ed Esperienza Utente di primo livello sia per Web App che Mobile App customizzate sul cliente"
            }
            Icona={UiDesign}
            ordine=" order-5 "
          />
          <Tile
            titolo={"APP DESIGN & DEVELOPMENT"}
            testo={
              "Progettiamo e realizziamo App iOS & Android anche integrate con sistemi esterni."
            }
            Icona={App}
            ordine=" order-4 "
          />
          <Tile
            titolo={"SVILUPPO SU MISURA"}
            testo={
              "Gestionali, Booking, School Manager, HR, POS, Ecommerce, ecc."
            }
            Icona={Sviluppo}
            ordine=" order-first "
          />
          <Tile
            titolo={"API DEVELOPMENT"}
            testo={
              "Sviluppiamo infrastrutture per startup e aziende adatte alla comunicazione fra dispositivi e prodotti di terze parti."
            }
            Icona={Api}
            ordine=" order-3 "
          />
        </div>
      </div>
    </section>
  );
}

function Tile({ titolo, testo, Icona, ordine }) {
  return (
    <div
      className={`z-10 flex h-auto max-h-[23rem] max-w-[23rem] flex-col items-center justify-center gap-4 rounded-sm bg-white/50 p-10 shadow-sm dark:bg-[#444444] md:w-[30%] md:hover:shadow-xl lg:min-h-[368px] ${ordine} `}
    >
      <div className="flex h-40 w-40 flex-none items-center justify-center">
        <img src={Icona} draggable="false" alt="" />
      </div>
      <p className="text-center text-lg font-bold">{titolo}</p>
      <p className="text-center">{testo}</p>
    </div>
  );
}
