import Phone from "../img/Phone.webp";
import Email from "../img/Email.webp";
import GPS from "../img/GPS.webp";

export default function Footer() {
  return (
    <section id="footer" className="mt-20 flex flex-col gap-8">
      <div className="flex flex-col items-center gap-8 px-4 md:flex-row md:items-start md:justify-around">
        <div className="flex w-72 flex-row gap-2 md:w-auto">
          <div className=" flex h-16 w-16 items-center justify-center ">
            <img src={GPS} draggable="false" alt="" loading="lazy" />
          </div>

          <div className="flex flex-col">
            <p className="text-justify text-4xl font-bold capitalize ">
              indirizzo
            </p>
            <p className="text-lg font-medium capitalize leading-loose text-black/80 dark:text-slate-100 ">
              Via Cesenatico 5075, <br /> Cesena (FC) 47521
            </p>
          </div>
        </div>
        <div className="flex w-72 flex-row gap-2 md:w-auto">
          <div className=" flex max-h-16 w-16 items-center justify-center ">
            <img src={Phone} draggable="false" alt="" loading="lazy" />
          </div>
          <div className="flex flex-col">
            <p className="text-justify text-4xl font-bold capitalize ">
              Telefono
            </p>
            <a
              href="tel:+393317446939"
              className="text-lg font-medium capitalize leading-loose text-[#CD122F] dark:text-[#DA0037]"
            >
              +39 3317446939
            </a>
          </div>
        </div>
        <div className="flex w-72 flex-row gap-2 md:w-auto">
          <div className=" flex h-16 w-16 items-center justify-center ">
            <img src={Email} draggable="false" alt="" loading="lazy" />
          </div>
          <div className="flex flex-col">
            <p className="text-justify text-4xl font-bold capitalize ">Email</p>
            <a
              href="mailto:info@boosterhub.it"
              className="text-lg font-medium leading-loose text-[#CD122F] dark:text-[#DA0037] "
            >
              info@boosterhub.it
            </a>
            <a
              href="mailto:tecnico@boosterhub.it"
              className="text-lg font-medium leading-loose text-[#CD122F] dark:text-[#DA0037] "
            >
              tecnico@boosterhub.it
            </a>
          </div>
        </div>
      </div>
      <span className=" flex h-9 items-center justify-center gap-1 border-t border-black/25 dark:border-[#444444] text-[9px] font-semibold text-black/80 dark:text-slate-100 md:h-12 md:text-sm">
        Copyright 2022{" "}
        <span className="text-[#CD122F] dark:text-[#DA0037] ">
          {" "}
          Booster Hub{" "}
        </span>{" "}
        brand di Giunchi Molinari SRL PIVA: 04547090409
      </span>
    </section>
  );
}
