import tecnologie from "./tecnologie.json";
export default function Tecnologie() {
  return (
    <section
      id="tecnologie"
      className=" my-10 flex flex-col items-center justify-center gap-10"
    >
      <div className="flex flex-col items-center justify-center gap-6 px-2 text-center md:w-1/3 ">
        <p className="text-3xl font-bold text-[#CD122F]">Tecnologie</p>
        <span className="h-0.5 w-14 bg-[#CD122F] " />
        <p className="font-medium">
          Quando lavoriamo su un nuovo progetto vogliamo farlo al meglio. Queste
          sono le tecnologie che adottiamo per creare piattaforme moderne e
          sicure.
        </p>
      </div>
      <span className="my-4 flex h-[120px] w-full flex-row items-center gap-8 overflow-x-auto bg-black/5 px-4 py-8 shadow-inner shadow-black/25  dark:bg-[#4d4d4d] dark:shadow-[#171717]/70 md:justify-evenly ">
        {tecnologie.map((data) => (
          <img
            key={data.alt}
            className="h-[75%] w-auto cursor-pointer object-fill hover:opacity-100 dark:hover:brightness-125 md:h-full md:opacity-70"
            src={data.src}
            draggable="false"
            alt={data.alt}
            onClick={() => window.open(data.href, "_blank")}
            loading="lazy"
          />
        ))}
      </span>
    </section>
  );
}
