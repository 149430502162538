import FrameLeft from "../img/BackgroundSinistra.webp";
import FrameTopRight from "../img/BackgroundDestra.webp";
import Brainstorming from "../img/brainstorming.webp";
import Mockup from "../img/mockup.webp";
import Development from "../img/development.webp";
import Esecuzione from "../img/esecuzione.webp";

export default function ProcessoDilavoro() {
  return (
    <section className=" relative my-10 flex h-[30rem] w-full flex-col items-center justify-center gap-8 bg-[#961125] px-2 py-6 text-center text-white md:justify-around md:gap-0">
      <img
        src={FrameLeft}
        draggable="false"
        alt=""
        className="left-2 bottom-6 z-0 hidden h-auto -rotate-[30deg] select-none md:absolute md:w-[18%] xl:inline "
        loading="lazy"
      />
      <img
        src={FrameTopRight}
        draggable="false"
        alt=""
        className="right-2 z-0 hidden h-auto select-none md:absolute md:-top-12 md:w-[10%] xl:inline  "
        loading="lazy"
      />
      {/* 
            <img src={FrameLeft} className="absolute left-0 bottom-0 select-none z-0 " alt="" />
            <img src={FrameTopRight} className="absolute right-2 -top-[12rem] select-none z-0 " alt="" /> 
           */}
      <p className="text-3xl font-semibold capitalize md:text-4xl">
        Processo di lavoro
      </p>
      <span className=" relative flex h-52 w-full snap-x snap-mandatory flex-row justify-between gap-4 overflow-y-hidden overflow-x-scroll border-b-2 border-white text-base scrollbar-hide md:w-auto md:snap-none md:gap-12 md:overflow-visible md:scrollbar-default">
        <div className=" flex w-full shrink-0 snap-center flex-col items-center justify-center gap-4 text-center font-medium text-white md:w-auto md:shrink">
          <img
            src={Brainstorming}
            draggable="false"
            alt=""
            className=" h-24 w-24"
            loading="lazy"
          />
          <p>Concept & Brainstorming</p>
          <p className=" absolute -bottom-2 h-4 w-4 rounded-full bg-white" />
        </div>
        <div className=" flex w-full shrink-0 snap-center flex-col items-center justify-center gap-4 font-medium md:w-auto  md:shrink">
          <img
            src={Mockup}
            draggable="false"
            alt=""
            className=" h-24 w-24"
            loading="lazy"
          />
          <p>Sketch & Mockup</p>
          <p className="absolute -bottom-2 h-4 w-4 rounded-full bg-white" />
        </div>
        <div className=" flex w-full shrink-0 snap-center flex-col items-center justify-center gap-4 font-medium md:w-auto  md:shrink">
          <img
            src={Development}
            draggable="false"
            alt=""
            className=" h-24 w-24"
            loading="lazy"
          />
          <p>Research & Development</p>
          <p className="absolute -bottom-2 h-4 w-4 rounded-full bg-white" />
        </div>
        <div className=" flex w-full shrink-0 snap-center flex-col items-center justify-center gap-4 font-medium md:w-auto  md:shrink">
          <img
            src={Esecuzione}
            draggable="false"
            alt=""
            className=" h-24 w-24"
            loading="lazy"
          />
          <p>Esecuzione & Installazione</p>
          <p className="absolute -bottom-2 h-4 w-4 rounded-full bg-white" />
        </div>
      </span>
      <p className=" flex text-sm font-medium md:w-1/3 md:font-semibold">
        Ci immergiamo nelle vostre idee, cerchiamo di assimilarle per pensare
        allo stesso modo e vi consegniamo quello che volete.
      </p>
    </section>
  );
}
