import React, { useState } from "react";
import Images from "./images.json";

export default function Portfolio() {
  const [imageModal, setImageModal] = useState({ src: "", isOpen: false });

  return (
    <section
      id="portfolio"
      className=" my-10 flex flex-col items-center justify-center gap-16"
    >
      <div className="flex flex-col items-center justify-center gap-6 px-2 text-center md:w-1/3 ">
        <p className="text-3xl font-bold">
          Il Nostro <span className="text-[#CD122F]">Portfolio</span>
        </p>
        <span className="h-0.5 w-14 bg-[#CD122F] " />
        <p className="font-medium">
          Siti Web e App create negli anni. Lavori interni, per startups o per
          aziende.
        </p>
      </div>
      <div className=" w-4/5 columns-sm gap-4 space-y-4">
        {Images.map((image) => (
          <img
            key={image.name}
            src={require("../img/" + image.name + image.type)}
            draggable="false"
            alt={image.name}
            className=" aspect-auto max-h-[70vh] w-full cursor-pointer rounded-lg object-cover shadow-md transition-opacity duration-300 opacity-100 hover:opacity-70 dark:brightness-90"
            onClick={(e) => setImageModal({ src: e.target.src, isOpen: true })}
            loading="lazy"
          />
        ))}
      </div>
      {imageModal.isOpen && (
        <ImageModal
          image={imageModal.src}
          Close={() => setImageModal({ src: "", isOpen: false })}
        />
      )}
    </section>
  );
}

function ImageModal({ image, Close }) {
  return (
    <div
      className=" fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center overflow-auto bg-black/90 py-[100px]  backdrop-blur"
      onClick={Close}
    >
      <span className=" absolute top-4 right-8 text-[40px] font-bold text-[#f1f1f1] duration-300 hover:cursor-pointer hover:text-[#bbb] hover:no-underline ">
        &times;
      </span>

      <img
        src={image}
        className=" m-auto block max-h-full max-w-[80%] object-contain "
        onClick={(e) => e.stopPropagation()}
        alt=""
        loading="lazy"
      />

      {/*  Titolo
      <div className="m-auto block w-4/5 text-center text-[#ccc] pt-2.5 h-36 "></div> 
      */}
    </div>
  );
}

// ░░░░░░░░░░░░░░▄▄▄▄▄▄▄▄▄▄▄▄░░░░░░░░░░░░░░
// ░░░░░░░░░░░░▄████████████████▄░░░░░░░░░░
// ░░░░░░░░░░▄██▀░░░░░░░▀▀████████▄░░░░░░░░
// ░░░░░░░░░▄█▀░░░░░░░░░░░░░▀▀██████▄░░░░░░
// ░░░░░░░░░███▄░░░░░░░░░░░░░░░▀██████░░░░░
// ░░░░░░░░▄░░▀▀█░░░░░░░░░░░░░░░░██████░░░░
// ░░░░░░░█▄██▀▄░░░░░▄███▄▄░░░░░░███████░░░
// ░░░░░░▄▀▀▀██▀░░░░░▄▄▄░░▀█░░░░█████████░░
// ░░░░░▄▀░░░░▄▀░▄░░█▄██▀▄░░░░░██████████░░
// ░░░░░█░░░░▀░░░█░░░▀▀▀▀▀░░░░░██████████▄░
// ░░░░░░░▄█▄░░░░░▄░░░░░░░░░░░░██████████▀░
// ░░░░░░█▀░░░░▀▀░░░░░░░░░░░░░███▀███████░░
// ░░░▄▄░▀░▄░░░░░░░░░░░░░░░░░░▀░░░██████░░░
// ██████░░█▄█▀░▄░░██░░░░░░░░░░░█▄█████▀░░░
// ██████░░░▀████▀░▀░░░░░░░░░░░▄▀█████████▄
// ██████░░░░░░░░░░░░░░░░░░░░▀▄████████████
// ██████░░▄░░░░░░░░░░░░░▄░░░██████████████
// ██████░░░░░░░░░░░░░▄█▀░░▄███████████████
// ███████▄▄░░░░░░░░░▀░░░▄▀▄███████████████
