import React from "react"
import { generateHeadTags } from "react-seo-tools/lib/generateHeadTags";
import { Helmet } from "react-helmet";
import Servizi from "./component/servizi";
import Progetti from "./component/progetti";
import ProcessoDilavoro from "./component/processoDiLavoro";
import Portfolio from "./component/portfolio";
import Tecnologie from "./component/tecnologie";
import Footer from "./component/footer";
import Contatti from "./component/contatti";
import Navbar from "./component/navbar";

export default function App() {
  return (
    <section id="home" className=" dark:bg-[#171717] dark:text-[#FAFAFA] ">
      <Helmet>
      {generateHeadTags({
          title: 'Booster Hub - Home',
          description: "L'obiettivo è costruire app e siti web esteticamente piacevoli, progettati per guidare la crescita delle aziende leader nel mondo.",
          openGraph: {
            type: 'website',
            title: 'Home',
            description: "L'obiettivo è costruire app e siti web esteticamente piacevoli, progettati per guidare la crescita delle aziende leader nel mondo.",
            image: '',
          },
        })}
      </Helmet>
      <Navbar />
      <Servizi />
      <Progetti />
      <ProcessoDilavoro />
      <Portfolio />
      <Tecnologie />
      <Contatti />
      <Footer />
    </section>
  );
}
