import { React, useRef, useState, useEffect } from "react";
import { ReactComponent as Logo } from "../img/LOGO 1.svg";
import { HashLink } from "react-router-hash-link";
// import Shape from "../img/Shape_1.webp"
// import Shape2 from "../img/Shape_2.webp"
// import Shape3 from "../img/Shape.webp"

export default function Navbar() {
  const menu = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const lineAfter =
    "after:transition after:ease-in-out after:transform after:duration-300 after:absolute after:block after:bg-black dark:after:bg-[#EDEDED] after:w-full after:h-1 after:rounded-full after:mt-2";
  const lineBefore =
    " before:transition before:ease-in-out before:transform before:duration-300  before:absolute  before:block  before:-mt-2 before:bg-black dark:before:bg-[#EDEDED] before:w-full before:h-1 before:rounded-full";
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  // const ScrollTo = (e) => {
  //   document.getElementById(e).scrollIntoView({
  //     behavior: "smooth",
  //   });
  // };

  const scrollWithOffset = (el) => {
    console.log(
      el.id,
      el?.id !== "home" ? (el?.id === "contatti" ? 0 : -80) : 0
    );
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset =
      el?.id !== "home" ? (el?.id === "contatti" ? 0 : -80) : -200;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  const toggleMenu = () => {
    if (isOpen) {
      setIsOpen(false);
      document.body.classList.remove("overflow-y-hidden");
    } else {
      setIsOpen(true);
      document.body.classList.add("overflow-y-hidden");
    }
  };

  useEffect(() => {
    window.onload = handleScroll;
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      id="Navbar"
      className="fixed top-0 z-50 flex h-16 w-screen items-center justify-center  bg-white/60 backdrop-blur-sm dark:bg-[#171717]/75 dark:backdrop-blur-md md:relative md:h-32 md:w-full md:border-0 md:border-transparent md:bg-transparent md:backdrop-filter-none "
    >
      <span className=" md:relative md:w-[95%] lg:w-[75%] xl:w-3/5 2xl:w-[50%]">
        <Logo
          onClick={() => (window.location.href = "/")}
          className="h-auto w-52 cursor-pointer dark:invert dark:brightness-50 md:mb-7 "
        />
        {/* <img src={Shape} className=" hidden -z-50 absolute -bottom-32 -left-20 w-44 h-auto md:block " alt="" draggable={false} /> */}
        {/* <img src={Shape2} className=" hidden -z-50 absolute -bottom-[140%]  left-1/4 w-1/2 h-auto md:block " alt="" draggable={false} /> */}
        {/* <img src={Shape3} className=" hidden -z-50 absolute -bottom-28 -right-16 w-44 h-auto md:block " alt="" draggable={false} /> */}
      </span>
      <div
        onClick={toggleMenu}
        className=" absolute right-4 z-50 flex h-7 w-7 items-center justify-center md:hidden"
      >
        <span
          className={` ${lineBefore} absolute block h-1 w-full transform rounded-full bg-black transition duration-300 ease-in-out dark:bg-[#EDEDED] ${lineAfter} ${
            isOpen
              ? "bg-opacity-0 before:translate-y-2 before:rotate-45 before:opacity-100 after:-translate-y-2 after:-rotate-45 after:opacity-100 dark:bg-opacity-0 "
              : ""
          } `}
        />
      </div>
      <span
        id="desktop"
        className={`hidden h-14 flex-row items-center justify-between rounded-[20px] border-2 border-solid border-[rgba(255,255,255,0.18)] bg-[#CD122F]/60 backdrop-blur-sm md:flex md:w-[95%] lg:w-[75%] xl:w-3/5 2xl:w-[50%] ${
          scrollPosition >= 100 ? " fixed top-4 " : "absolute -bottom-7 "
        } `}
      >
        <span className="ml-4 flex flex-row items-center gap-2 font-semibold capitalize text-white">
          <HashLink
            to="/#home"
            scroll={(el) => scrollWithOffset(el)}
            className="flex cursor-pointer items-center px-4 py-2 hover:rounded-full hover:bg-white hover:text-black"
          >
            Home
          </HashLink>
          <HashLink
            to="/#servizi"
            scroll={(el) => scrollWithOffset(el)}
            className="flex cursor-pointer items-center px-4 py-2 hover:rounded-full hover:bg-white hover:text-black"
          >
            Servizi
          </HashLink>
          <HashLink
            to="/#tecnologie"
            scroll={(el) => scrollWithOffset(el)}
            className="flex cursor-pointer items-center px-4 py-2 hover:rounded-full hover:bg-white hover:text-black"
          >
            Tecnologie
          </HashLink>
          {/* <HashLink
            to="/#team"
            scroll={(el) => scrollWithOffset(el)}
            className="flex cursor-pointer items-center px-4 py-2 hover:rounded-full hover:bg-white hover:text-black"
          >
            Team
          </HashLink> */}
          <HashLink
            to="/#contatti"
            scroll={(el) => scrollWithOffset(el)}
            className="flex cursor-pointer items-center px-4 py-2 hover:rounded-full hover:bg-white hover:text-black"
          >
            Contatti
          </HashLink>

          {/* <p
            onClick={() => ScrollTo("home")}
            className="flex cursor-pointer items-center px-4 py-2 hover:rounded-full hover:bg-white hover:text-black"
          >
            Home
          </p>
          <p
            onClick={() => ScrollTo("servizi")}
            className="flex cursor-pointer items-center  px-4 py-2 hover:rounded-full hover:bg-white hover:text-black"
          >
            Servizi
          </p>
          <p
            onClick={() => ScrollTo("tecnologie")}
            className="flex cursor-pointer items-center  px-4 py-2 hover:rounded-full hover:bg-white hover:text-black"
          >
            Tecnologie
          </p>
          {/* <p onClick={ () => ScrollTo("Progetti") } className="hover:bg-white hover:rounded-full cursor-pointer  hover:text-black px-4 flex items-center">
                        Progetti
                    </p>
                    <p onClick={ () => ScrollTo("portfolio") } className="hover:bg-white hover:rounded-full cursor-pointer  hover:text-black px-4 flex items-center">
                        Portfolio
                    </p> */}
          {/*
          <p
            onClick={() => ScrollTo("team")}
            className="flex cursor-pointer items-center  px-4 py-2 hover:rounded-full hover:bg-white hover:text-black"
          >
            Team
          </p>
          {/* <p onClick={ () => ScrollTo("") } className="hover:bg-white hover:rounded-full cursor-pointer  hover:text-black px-4 flex items-center">
                        Chi Siamo
                    </p> */}
          {/*
          <p
            onClick={() => ScrollTo("contatti")}
            className="flex cursor-pointer items-center  px-4 py-2 hover:rounded-full hover:bg-white hover:text-black"
          >
            Contatti
                    </p>*/}
        </span>
        <HashLink
          to={"/preventivo"}
          className="mr-6 rounded-full bg-gradient-to-r from-[#CD122F] to-[#961125] px-4 py-2 font-semibold text-white active:opacity-90"
        >
          Preventivo Rapido
        </HashLink>
        {/*<Link
          to={"/preventivo"}
          onClick={() =>
            console.log(document.getElementById("desktop").offsetTop)
          }
          className="mr-6 rounded-full bg-gradient-to-r from-[#CD122F] to-[#961125] px-4 py-2 font-semibold text-white active:opacity-90"
        >
          Preventivo Rapido
        </Link> */}
      </span>
      <div
        ref={menu}
        className={` fixed top-0  z-40 flex h-screen w-screen flex-col items-center justify-center gap-4 bg-white text-2xl font-semibold opacity-0 transition-all duration-300 ease-in-out  dark:bg-[#171717] dark:text-[#FAFAFA]  ${
          isOpen ? "visible opacity-100" : "invisible"
        } `}
      >
        <HashLink
          to="/#home"
          scroll={(el) => scrollWithOffset(el)}
          onClick={() => toggleMenu()}
          className="flex cursor-pointer items-center px-4 hover:rounded-full hover:bg-white hover:text-black"
        >
          Home
        </HashLink>
        <HashLink
          to="/#servizi"
          scroll={(el) => scrollWithOffset(el)}
          onClick={() => toggleMenu()}
          className="flex cursor-pointer items-center px-4 hover:rounded-full hover:bg-white hover:text-black"
        >
          Servizi
        </HashLink>
        <HashLink
          to="/#tecnologie"
          scroll={(el) => scrollWithOffset(el)}
          onClick={() => toggleMenu()}
          className="flex cursor-pointer items-center px-4 hover:rounded-full hover:bg-white hover:text-black"
        >
          Tecnologie
        </HashLink>
        {/* <HashLink
          to="/#team"
          scroll={(el) => scrollWithOffset(el)}
          onClick={() => toggleMenu()}
          className="flex cursor-pointer items-center px-4 hover:rounded-full hover:bg-white hover:text-black"
        >
          Team
        </HashLink> */}
        <HashLink
          to="/#contatti"
          scroll={(el) => scrollWithOffset(el)}
          onClick={() => toggleMenu()}
          className="flex cursor-pointer items-center px-4 hover:rounded-full hover:bg-white hover:text-black"
        >
          Contatti
        </HashLink>
        <HashLink
          to={"/preventivo"}
          onClick={() => {
            toggleMenu();
          }}
          className="flex h-12 items-center justify-center rounded-full bg-gradient-to-r from-[#CD122F] to-[#961125] px-4 text-center font-semibold text-white active:bg-opacity-90"
        >
          Preventivo Rapido
        </HashLink>
        {/* <p
          onClick={() => {
            toggleMenu();
            ScrollTo("home");
          }}
          className="flex cursor-pointer items-center px-4 hover:rounded-full hover:bg-white hover:text-black"
        >
          Home
        </p>
        <p
          onClick={() => {
            toggleMenu();
            ScrollTo("servizi");
          }}
          className="flex cursor-pointer items-center  px-4 hover:rounded-full hover:bg-white hover:text-black"
        >
          Servizi
        </p>
        <p
          onClick={() => {
            toggleMenu();
            ScrollTo("tecnologie");
          }}
          className="flex cursor-pointer items-center  px-4 hover:rounded-full hover:bg-white hover:text-black"
        >
          Tecnologie
        </p>
        {/* <p onClick={ () => ScrollTo("Progetti") } className="hover:bg-white hover:rounded-full cursor-pointer  hover:text-black px-4 flex items-center">
                    Progetti
                </p>
                <p onClick={ () => ScrollTo("portfolio") } className="hover:bg-white hover:rounded-full cursor-pointer  hover:text-black px-4 flex items-center">
                    Portfolio
                </p> */}
        {/*
        <p
          onClick={() => {
            toggleMenu();
            ScrollTo("team");
          }}
          className="flex cursor-pointer items-center  px-4 hover:rounded-full hover:bg-white hover:text-black"
        >
          Team
        </p>
        {/* <p onClick={ () => ScrollTo("") } className="hover:bg-white hover:rounded-full cursor-pointer  hover:text-black px-4 flex items-center">
                    Chi Siamo
                </p> */}
        {/*
        <p
          onClick={() => {
            toggleMenu();
            ScrollTo("contatti");
          }}
          className="flex cursor-pointer items-center  px-4 hover:rounded-full hover:bg-white hover:text-black"
        >
          Contatti
        </p>*/}
      </div>
    </div>
  );
}
