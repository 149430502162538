import React from "react";
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import PreventivoRapido from "./component/preventivoRapido";
//import Navbar from "./component/navbar";

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <BrowserRouter>
  {/* <Navbar /> */}
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/preventivo" element={<PreventivoRapido />} />
    </Routes>
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
