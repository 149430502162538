//import Device from "../img/Group 70.png";
import { React, useState } from "react";
import BeeCreatedDevice from "../img/BeeCreatedGroup.webp";
import BeeCreatedLogo from "../img/BeeCreatedLogo.webp";
import YmpaDevices from "../img/YmpaDevices.webp";
import YmpaLogo from "../img/YMPALogo.webp";
import MnrvLogo from "../img/mnrv-logo.webp";
import MnrvDevices from "../img/MNRVDevices.webp";
import KeyGoLogo from "../img/KeyGoLogo.webp";

export default function Progetti() {
  const [infoProgetti, setInfoProgetti] = useState({
    nome: "YMPA",
    descrizione:
      "Sistema gestionale e di controllo per le officine meccaniche. \n Le principali funzionalità comprendono prenotazione cliente, ordini fornitori, analisi finanziaria e fatturazione elettronica. \n È un sistema per il management di un’officina a 360°, dalla gestione clienti, al marketing, dagli acquisti al controllo finanziario.",
    foto: YmpaDevices,
  });

  const [active, setActive] = useState({
    progetto1: true,
    progetto2: false,
    progetto3: false,
    progetto4: false,
  });

  return (
    <section
      id="Progetti"
      className="flex flex-col items-center justify-center gap-8 px-4 md:px-10"
    >
      <span className="flex flex-col items-center gap-4 px-2 text-center md:w-1/3">
        <p className="text-3xl font-bold">
          I Nostri <span className="text-[#CD122F]">Progetti</span>
        </p>
        <span className="h-0.5 w-14 bg-[#CD122F] " />
        <p className="font-medium">
          Dal 2018, aiutiamo le aziende a raggiungere i loro obiettivi con
          soluzioni software specifiche per l'industria, impattanti e
          innovative.
        </p>
      </span>
      <div className=" z-0 mt-8 flex w-full flex-row gap-2 overflow-x-auto px-4 scrollbar-hide md:items-center md:justify-center md:gap-6 md:scrollbar-default">
        <BoxProgetti
          id={"YMPA"}
          setStato={(data) => setActive(data)}
          stato={active?.progetto1}
          setInfoProgetti={(data) => setInfoProgetti(data)}
          Icona={YmpaLogo}
        />
        <BoxProgetti
          id={"KeyGo"}
          setStato={(data) => setActive(data)}
          stato={active?.progetto2}
          setInfoProgetti={(data) => setInfoProgetti(data)}
          Icona={KeyGoLogo}
        />
        <BoxProgetti
          id={"Beecreated"}
          setStato={(data) => setActive(data)}
          stato={active?.progetto3}
          setInfoProgetti={(data) => setInfoProgetti(data)}
          Icona={BeeCreatedLogo}
        />
        <BoxProgetti
          id={"MNRV"}
          setStato={(data) => setActive(data)}
          stato={active?.progetto4}
          setInfoProgetti={(data) => setInfoProgetti(data)}
          Icona={MnrvLogo}
        />
      </div>
      <div
        id="boxContenuto"
        className=" -mt-10 box-border flex w-full flex-col items-center justify-center gap-4 rounded-md border px-6 py-8 shadow-lg shadow-[#cd122f]/20 dark:border-0 dark:bg-[#444444] dark:shadow-none md:h-96 md:flex-row md:gap-0 xl:w-[75%] 2xl:w-[55%] "
      >
        <div className="flex flex-col gap-2 md:w-1/2">
          <p className="text-2xl font-bold md:text-4xl">{infoProgetti?.nome}</p>
          <p className="h-0.5 w-12 bg-[#CD122F] " />
          <p className="text-sm md:text-base">{infoProgetti?.descrizione}</p>
        </div>
        {infoProgetti?.foto !== undefined ? (
          <img src={infoProgetti?.foto} alt="" className="h-auto md:w-1/2" />
        ) : (
          <div className="h-auto md:w-1/2" />
        )}
      </div>
    </section>
  );
}

function BoxProgetti({ id, setInfoProgetti, stato, setStato, Icona }) {
  const MostraContenuto = (e) => {
    switch (id) {
      case "YMPA":
        setInfoProgetti({
          nome: id,
          descrizione:
            "Sistema gestionale e di controllo per le officine meccaniche. \n Le principali funzionalità comprendono prenotazione cliente, ordini fornitori, analisi finanziaria e fatturazione elettronica. \n È un sistema per il management di un’officina a 360°, dalla gestione clienti, al marketing, dagli acquisti al controllo finanziario.",
          foto: YmpaDevices,
        });
        setStato({
          progetto1: true,
          progetto2: false,
          progetto3: false,
          progetto4: false,
        });
        break;
      case "KeyGo":
        setInfoProgetti({
          nome: id,
          descrizione:
            "Progetto IoT nel settore del Car Sharing.\n KeyGo si occupa in particolare di Car Sharing P2P, quindi installando un dispositivo OBDII nell’auto privata si può condividere il proprio mezzo con la comunità e guadagnare mentre non si usa.\n Il dispositivo comunica con l’app continuamente e funge anche da scatola nera.",
          //foto: Device,
        });
        setStato({
          progetto1: false,
          progetto2: true,
          progetto3: false,
          progetto4: false,
        });
        break;
      case "Beecreated":
        setInfoProgetti({
          nome: id,
          descrizione:
            "Settore della stampa 3D. Beecreated è una suite completa di Software, Macchine e Materiali per il settore Additive Manufacturing professionale.\n Abbiamo sviluppato la piattaforma di management delle stampanti, per potere gestire più macchine contemporaneamente da remoto, sia la piattaforma web per l’ordine rapido da parte del cliente di un componente da stampare.\n La funzione principale riguarda il preventivo rapido di una stampa 3D.",
          foto: BeeCreatedDevice,
        });
        setStato({
          progetto1: false,
          progetto2: false,
          progetto3: true,
          progetto4: false,
        });
        break;
      case "MNRV":
        setInfoProgetti({
          nome: id,
          descrizione:
            "E-commerce di merch personalizzato. In particolare MNRV for School produce abbigliamento personalizzato per gli istituti superiori italiani ed è ad uso esclusivo degli studenti.\n In particolare è stata sviluppata una dashboard di gestione ordini e pagamenti utilizzabile anche dai rappresentanti di istituto.\n Sono integrate poi varie funzioni di re-marketing.",
          foto: MnrvDevices,
        });
        setStato({
          progetto1: false,
          progetto2: false,
          progetto3: false,
          progetto4: true,
        });
        break;

      default:
        break;
    }
  };
  return (
    <div
      onClick={() => MostraContenuto()}
      className={
        stato
          ? " relative mb-8 box-border flex h-auto w-56 cursor-pointer flex-col items-center justify-center rounded-md border bg-white px-4 py-2 text-center shadow-[0_6px_15px_0px] shadow-[#CD122F]/20 after:absolute after:top-full after:left-1/2 after:-ml-[10px] after:border-[10px] after:border-transparent after:border-t-white dark:border-0 dark:bg-[#EDEDED] dark:text-[#171717]  dark:after:border-t-[#EDEDED] "
          : " relative mb-8 box-border flex h-auto w-56 cursor-pointer flex-col items-center justify-center rounded-md border bg-[#F1F3F6] px-4 py-2 text-center dark:border-0 dark:bg-[#444444]"
      }
    >
      <div className="h-16 w-16">
        <img
          src={Icona}
          className=" h-full w-full object-contain  "
          draggable="false"
          alt=""
        />
      </div>
      <p className="font-semibold line-clamp-2 md:text-lg">{id}</p>
    </div>
  );
}
